import { EventTheme } from "@app/models/event-theme";
import { Message } from "@tellsy/common/services/stomp.model";
import { RandomizationVariant, SubEventsLink, Webinar } from "..";

export enum EventMessageType {
  // Event
  EVENT_NAME_CHANGED = "EVENT_NAME_CHANGED",
  WEBINAR_CHANGED = "WEBINAR_CHANGED",
  SHARED_IFRAME_URL_CHANGED = "SHARED_IFRAME_URL_CHANGED",
  INFORMATION_FOR_PARTICIPANT_CHANGED = "INFORMATION_FOR_PARTICIPANT_CHANGED",

  TRACKING_PARTICIPANT_TAB_CHANGED = "TRACKING_PARTICIPANT_TAB_CHANGED",

  // SubEvents
  SUB_EVENT_LINKS_CHANGED = "SUB_EVENT_LINKS_CHANGED",
  FEEDBACK_SUB_EVENT_ID_CHANGED_MESSAGE = "FEEDBACK_SUB_EVENT_ID_CHANGED_MESSAGE",

  // Activity
  ACTIVITY_CREATED = "ACTIVITY_CREATED",
  ACTIVITY_NAME_CHANGED = "ACTIVITY_NAME_CHANGED",
  ACTIVITIES_CHANGED = "ACTIVITIES_CHANGED",
  COMMON_ACTIVITY_CHANGED = "COMMON_ACTIVITY_CHANGED",

  // Activity for team
  TEAM_ACTIVITY_CHANGED = "TEAM_ACTIVITY_CHANGED",

  // Theme
  THEME_CHANGED = "THEME_CHANGED",
  EVENT_LOGO_CHANGED = "EVENT_LOGO_CHANGED",

  // Timer
  TIMER_STARTED = "TIMER_STARTED",
  TIMER_PAUSED = "TIMER_PAUSED",
  TIMER_CAN_BE_NEGATIVE_CHANGED = "TIMER_CAN_BE_NEGATIVE_CHANGED",

  // Projector
  PROJECTOR_ACTIVITY_CHANGED = "PROJECTOR_ACTIVITY_CHANGED",
  // TODO: check if needed
  NUMBER_OF_OPEN_PROJECTORS_CHANGED = "NUMBER_OF_OPEN_PROJECTORS_CHANGED",
  TIMER_FULLSCREEN_CHANGED = "TIMER_FULLSCREEN_CHANGED",

  // Workspace
  WORKSPACE_DOCUMENT_UPLOADED = "WORKSPACE_DOCUMENT_UPLOADED",
  WORKSPACE_DOCUMENT_DELETED = "WORKSPACE_DOCUMENT_DELETED",
  WORKSPACE_DOCUMENT_RENAMED = "WORKSPACE_DOCUMENT_RENAMED",
  WORKSPACE_DOCUMENT_HIDDEN_CHANGED = "WORKSPACE_DOCUMENT_HIDDEN_CHANGED",
  WORKSPACE_ALL_DOCUMENTS_PROTECTED_CHANGED = "WORKSPACE_ALL_DOCUMENTS_PROTECTED_CHANGED",
  WORKSPACE_ALL_DOCUMENTS_HIDDEN_CHANGED = "WORKSPACE_ALL_DOCUMENTS_HIDDEN_CHANGED",
  WORKSPACE_DOCUMENTS_ORDERS_CHANGED = "WORKSPACE_DOCUMENTS_ORDERS_CHANGED",

  WORKSPACE_SCHEDULE_CHANGED = "WORKSPACE_SCHEDULE_CHANGED",

  RANDOMIZATION_CHANGED = "RANDOMIZATION_CHANGED",

  // TODO: check
  PROJECTOR_ACTIVITIES_CHANGED = "PROJECTOR_ACTIVITIES_CHANGED",
}

export interface EventNameChangedMessage extends Message {
  name: string;
}

export interface SubEventLinksChangedMessage extends Message {
  parentEventId: string;
  parentEventName: string;
  eventLinks: SubEventsLink[];
}

export interface EventSharedIframeUrlChangedMessage extends Message {
  url: string;
}

export interface EventInformationForParticipantMessage extends Message {
  information: string;
}

export interface ParticipantsActivityTrackingChangedMessage extends Message {
  trackParticipantTab: boolean;
}

export interface EventWebinarChangedMessage extends Message {
  webinar: Webinar;
}

export interface FeedBackSubEventIdChangedMessage extends Message {
  feedbackSubEventId: string
}

export interface ThemeChangedMessage extends Message {
  theme: EventTheme;
}
export interface EvenetLogoChangedMessage extends Message {
  showEventLogo: boolean;
  newEventLogoFileName: string;
}

export interface RandomizationMessage extends Message {
  running: boolean;
  closed: boolean;
  variants: RandomizationVariant[];
  chosenVariant: RandomizationVariant;
}
