import { environment } from "@tellsy/environments/environment";

const HOST = environment.host;

const SERVICE_URL = {
  event: "/api/event_service",
  participant: "/api/participant_service",
  roleGame: "/api/role_play_game",
  voting: "/api/voting_service",
  moderator: "/api/moderator_service",
  askQuestion: "/api/ask_question_service",
  tagCloud: "/api/tag_cloud_service",
  fillVacancy: "/api/fill_vacancies_service",
  miniActivities: "/api/mini_activities_service",
  auth: "/api/auth_service",
  survey: "/api/survey_service",
  fileService: "/api/file_service",
  reportService: "/api/report_service",
  brainstorm: "/api/brainstorm_service",
  classification: "/api/classification_service",
};

const build = (part: keyof typeof SERVICE_URL, path: string) =>
  HOST + SERVICE_URL[part] + (path.startsWith("/") ? path : "/" + path);

export const API = {
  event: {
    loadEvent: (eventId: string) => build("event", `/event/${eventId}`),

    setWebinar: (eventId: string) =>
      build("event", `/event/${eventId}/webinar`),
    setSharedIframeUrl: (eventId: string) =>
      build("event", `/event/${eventId}/shared_iframe_url`),
    setInfoForParticipant: (eventId: string) =>
      build("event", `/event/${eventId}/information_for_participant`),
    setHelpPhone: (eventId: string) =>
      build("event", `/event/${eventId}/help_phone`),

    getTagCloudActivitiesForReport: () =>
      build("event", "/event/tag_cloud_activities_for_report"),
    downloadReport: (eventIds: string[]) =>
      build("reportService", "/report/") + eventIds.join("&"),

    setParticipantsActivityTracking: (eventId: string) =>
      build("event", `/event/${eventId}/track_participant_tab`),

    activity: {
      loadActiveActivity: (eventId: string) =>
        build("event", `/event/${eventId}/activity`),

      createActivity: (eventId: string) =>
        build("event", `/event/${eventId}/activity`),
      deleteActivity: (eventId: string, activityId: string) =>
        build("event", `event/${eventId}/activity/${activityId}`),
      deleteMultiActivity: (eventId: string, activityId: string) =>
        build("event", `event/${eventId}/activities?activityIds=${activityId}`),
      duplicateActivity: (eventId: string) =>
        build("event", `/event/${eventId}/activity/duplicate`),
      renameActivity: (eventId: string, activityId: string) =>
        build("event", `/event/${eventId}/activity/${activityId}/name`),
      setActivityOrder: (eventId: string, activityId: string) =>
        build("event", `/event/${eventId}/activity/${activityId}/order`),
      activateActivity: (eventId: string, activityId: string) =>
        build("event", `/event/${eventId}/activity/${activityId}/set_active`),
      deactivateActivity: (eventId: string) =>
        build("event", `/event/${eventId}/activity/null/set_active`),
      activateNextActivity: (eventId: string) =>
        build("event", `/event/${eventId}/activate_next_activity`),
      activatePrevActivity: (eventId: string) =>
        build("event", `/event/${eventId}/activate_previous_activity`),

      teams: {
        setTeamsForActivity: (eventId: string, activityId: string) =>
          build("event", `/event/${eventId}/activity/${activityId}/set_teams`),
        activateActivitiesForTeams: (eventId: string) =>
          build("event", `/event/${eventId}/activities/activate_for_teams`),
        deactivateActivitiesForTeams: (eventId: string) =>
          build("event", `/event/${eventId}/activities/deactivate_for_teams`),
      },
    },

    projector: {
      setProjectorActivity: (eventId: string) =>
        build("event", `/event/${eventId}/projector_activity`),
      setProjectorTimerFullscreen: (eventId: string) =>
        build("event", `/event/${eventId}/projector/show_fullscreen_timer`),
    },

    sessionLight: {
      startSessionLight: (eventId: string) => build("event", `/event/${eventId}/start`),
      finishSessionLight: (eventId: string) => build("event", `/event/${eventId}/finish`),
      remainingSeconds: (eventId: string) => build("event", `/event/${eventId}/remaining_seconds`),
    },

    timer: {
      load: (eventId: string) => build("event", `/event/${eventId}/timer`),
      start: () => build("event", `/event/timer/start`),
      stop: () => build("event", `/event/timer/pause`),
      setCanBeNegative: () =>
        build("event", `/event/timer/set_can_be_negative`),
    },

    workspace: {
      documents: {
        addDocument: (eventId: string) =>
          build("event", `/event/${eventId}/workspace/documents`),
        deleteDocument: (eventId: string, documentId: string) =>
          build("event", `/event/${eventId}/workspace/documents/${documentId}`),
        renameDocument: (eventId: string, documentId: string) =>
          build(
            "event",
            `/event/${eventId}/workspace/documents/${documentId}/rename`,
          ),
        replaceDocument: (eventId: string, documentId: string) =>
          build("event", `/event/${eventId}/workspace/documents/${documentId}`),
        setDocumentProtected: (eventId: string, documentId: string) =>
          build(
            "event",
            `/event/${eventId}/workspace/documents/${documentId}/protect`,
          ),
        setDocumentHidden: (eventId: string, documentId: string) =>
          build(
            "event",
            `/event/${eventId}/workspace/documents/${documentId}/hide`,
          ),
        setAllDocumentsProtected: (eventId: string) =>
          build(
            "event",
            `/event/${eventId}/workspace/documents/set_all_protected`,
          ),
        setAllDocumentsHidden: (eventId: string) =>
          build(
            "event",
            `/event/${eventId}/workspace/documents/set_all_hidden`,
          ),
        setDocumentsOrders: (eventId: string) =>
          build("event", `/event/${eventId}/workspace/documents/set_orders`),
      },
      schedule: {
        setSchedule: (eventId: string) =>
          build("event", `/event/${eventId}/workspace/schedule`),
        deleteSchedule: (eventId: string) =>
          build("event", `/event/${eventId}/workspace/schedule`),
        setScheduleHidden: (eventId: string) =>
          build("event", `/event/${eventId}/workspace/schedule/set_is_hidden`),
      },
    },
  },

  events: {
    getPageableEventsShortInfo: () => build("event", "/event/pageable"),

    createEvent: () => build("event", "/event"),
    deleteEvent: (eventId: string) => build("event", `/event/${eventId}`),

    getAllActivityTypes: () => build("event", "/activity_types"),

    duplicateEvent: () => build("event", "/event/duplicate"),
    setExpired: (eventId: string) =>
      build("event", `/event/${eventId}/set_expired`),
    setEventCode: (eventId: string) =>
      build("event", `/event/${eventId}/set_code`),
    setEventName: (eventId: string) =>
      build("event", `/event/${eventId}/set_name`),

    changeEventSettings: (eventId: string) =>
      build("event", `/event/${eventId}/settings`),

    setFeedbackWorkflow:(eventId: string) =>
      build("event", `/event/${eventId}/feedback_event`),

    changeEventTheme: (eventId: string) =>
      build("event", `/event/${eventId}/theme`),

    changeEventThemeLogo: (eventId: string) =>
      build("event", `/event/${eventId}/theme/logo`),
    deleteEventThemeLogo: (eventId: string) =>
      build("event", `/event/${eventId}/theme/logo`),

    generateEventCode: () => build("event", "/event/generate_code"),
    checkEventCodeExistence: (eventCode: string) =>
      build("event", `/event/check/${eventCode}`),

    setNewOwner: (moderatorId: string) =>
      build("event", `/events/${moderatorId}/change_owner`),
    grantAccess: (moderatorId: string) =>
      build("event", `/events/${moderatorId}/grant`),
    revokeAccess: (moderatorId: string) =>
      build("event", `/events/${moderatorId}/revoke`),
    loadEventsToGrantAccess: (moderatorId: string) =>
      build("event", `/events/${moderatorId}/list`),
    loadGrantedAccessEvents: (moderatorId: string) =>
      build("event", `/events/${moderatorId}/granted`),
    getEventNameByEventCode: (eventCode: string) =>
      build("event", `/events/${eventCode}/name`),
  },
  files: {
    getFilePath: (bucketId: string, filename: string) =>
      HOST + SERVICE_URL.fileService + `/file/${bucketId}/${filename}`,
    saveFile: (bucketId: string, filename: string) =>
      HOST + SERVICE_URL.fileService + `/file/${bucketId}/${filename}`,
  },
  participant: {
    addParticipantsFromExcel:
      HOST +
      SERVICE_URL.participant +
      "/event/{eventId}/add_participants_from_excel",
    getEventParticipantsConfig:
      HOST +
      SERVICE_URL.participant +
      "/event/{eventId}/event_participant_config",
    setDataFields: (participantConfigId: string) =>
      HOST +
      SERVICE_URL.participant +
      `/participant_config/${participantConfigId}/data_field`,
    getDataFieldsByEventId: HOST + SERVICE_URL.participant + "/data_fields",
    getDataFieldValues:
      HOST + SERVICE_URL.participant + "/data_field/{dataFieldId}/values",
    getParticipantsByEventId:
      HOST + SERVICE_URL.participant + "/participants/for_event",
    getParticipantsForSubEvent: (eventId: string) =>
      HOST + SERVICE_URL.participant + `/sub_event/${eventId}/participants`,
    participantInfo: HOST + SERVICE_URL.participant + "/participant/info",
    getParticipant: HOST + SERVICE_URL.participant + "/participant",
    ready: HOST + SERVICE_URL.participant + "/participant/ready",
    updateDataFieldsBindings: (participantConfigId) =>
      HOST +
      SERVICE_URL.participant +
      `/participant_config/${participantConfigId}/update_data_fields_bindings`,
    setFieldAsUsername: (participantConfigId, dataFieldId) =>
      HOST +
      SERVICE_URL.participant +
      `/participant_config/${participantConfigId}/data_field/${dataFieldId}/set_as_username`,
    setFieldAsIdentifier: (participantConfigId, dataFieldId) =>
      HOST +
      SERVICE_URL.participant +
      `/participant_config/${participantConfigId}/data_field/${dataFieldId}/set_as_identifier`,
    setFieldAsTeamName: (participantConfigId, dataFieldId) =>
      HOST +
      SERVICE_URL.participant +
      `/participant_config/${participantConfigId}/data_field/${dataFieldId}/set_as_team_name`,
    addParticipant: (eventId: string) =>
      HOST + SERVICE_URL.participant + `/event/${eventId}/participant`,
    isPlayerLoggedIn:
      HOST + SERVICE_URL.participant + "/participant/{participantId}/logged_in",
    logout: HOST + SERVICE_URL.participant + "/participant/logout",
    login: HOST + SERVICE_URL.participant + "/participant/login",
    fillParticipantData: HOST + SERVICE_URL.participant + "/participant/data",
    getTeams: (eventId: string) =>
      HOST + SERVICE_URL.participant + `/event/${eventId}/teams`,
    changeParticipantDataFieldsValues: (
      eventId: string,
      participantId: string,
    ) =>
      HOST +
      SERVICE_URL.participant +
      `/event/${eventId}/participant/${participantId}/change_data_fields_values`,
    changeParticipantTeamInSubEvent: (eventId: string, participantId: string) =>
      HOST +
      SERVICE_URL.participant +
      `/event/${eventId}/participant/${participantId}/change_team`,
    randomlyChangeParticipantTeams: (eventId: string) =>
      HOST +
      SERVICE_URL.participant +
      `/event/${eventId}/change_teams_randomly`,
    setParticipantsLoginType: (eventId: string) =>
      HOST +
      SERVICE_URL.participant +
      `/event/${eventId}/event_participant_config/login_type`,
    setActive: HOST + SERVICE_URL.participant + "/participant/set_active",
    setActiveOnClose: (participantId: string) =>
      build(
        "participant",
        `/participant/set_active_on_close?participantId=${participantId}&isActive=false`,
      ),
    setSelectedSubEventId:
      HOST + SERVICE_URL.participant + "/participant/selected_sub_event_id",
    sendAlert: HOST + SERVICE_URL.participant + `/event/alert`,
  },
  roleGame: {
    anyRole: {
      getAllResults: (gameId: string) =>
        HOST + SERVICE_URL.roleGame + `/game/${gameId}/all_results`,
    },
    moderator: {
      getGameInfo: (gameId: string) =>
        HOST + SERVICE_URL.roleGame + `/game/${gameId}`,
      getResultsReportInExcel: (gameId: string) =>
        HOST + SERVICE_URL.roleGame + `/game/${gameId}/excel_report`,
      game: {
        setPhase: (gameId: string) =>
          HOST + SERVICE_URL.roleGame + `/game/${gameId}/set_phase`,
        setRound: (gameId: string) =>
          HOST + SERVICE_URL.roleGame + `/game/${gameId}/set_round`,
        setMeetingConnectionUrl: (
          gameId: string,
          roundIndex: number,
          sectorIndex: number,
          meetingIndex: number,
        ) =>
          HOST +
          SERVICE_URL.roleGame +
          `/game/${gameId}/rounds/${roundIndex}/sector/${sectorIndex}/meeting/${meetingIndex}/connection_url`,
      },
      roles: {
        replaceParticipant: (gameId: string, roundIndex: number) =>
          HOST +
          SERVICE_URL.roleGame +
          `/game/${gameId}/round/${roundIndex}/replace_participant`,
        setMeetingRoomIndex: (gameId: string, participantId: string) =>
          HOST +
          SERVICE_URL.roleGame +
          `/game/${gameId}/participant/${participantId}/meeting_room_index`,
        removeParticipantFromMeeting: (
          gameId: string,
          roundIndex: number,
          participantId: string,
        ) =>
          HOST +
          SERVICE_URL.roleGame +
          `/game/${gameId}/round/${roundIndex}/participant/${participantId}/remove_from_meeting`,
        removeParticipantFromAllMeetings: (
          gameId: string,
          participantId: string,
        ) =>
          HOST +
          SERVICE_URL.roleGame +
          `/game/${gameId}/participant/${participantId}/remove_from_all_meetings`,
        addParticipantToMeeting: (
          gameId: string,
          roundIndex: number,
          meetingIndex: number,
          sectorIndex: number,
        ) =>
          HOST +
          SERVICE_URL.roleGame +
          `/game/${gameId}/round/${roundIndex}/meeting/${meetingIndex}/sector/${sectorIndex}/add_participant`,
        changeRole: (
          gameId: string,
          roundIndex: number,
          participantId: string,
        ) =>
          HOST +
          SERVICE_URL.roleGame +
          `/game/${gameId}/round/${roundIndex}/participant/${participantId}/change_role`,
      },
      settings: {
        saveGameModeSettings: (gameId: string) =>
          HOST + SERVICE_URL.roleGame + `/game/${gameId}/game_mode_settings`,
        addMeetingsConnectionUrlsFromExcel: (gameId: string) =>
          HOST +
          SERVICE_URL.roleGame +
          `/game/${gameId}/add_meetings_connection_urls_from_excel`,
        saveFileDistributionSettings: (gameId: string) =>
          HOST +
          SERVICE_URL.roleGame +
          `/game/${gameId}/file_distribution_settings`,
        saveRolesNames: (gameId: string) =>
          HOST + SERVICE_URL.roleGame + `/game/${gameId}/roles_names`,
        saveRoundsSettings: (gameId: string) =>
          HOST + SERVICE_URL.roleGame + `/game/${gameId}/rounds`,
        changeInstructionForPhase: (gameId: string) =>
          HOST + SERVICE_URL.roleGame + `/game/${gameId}/instruction_phase`,
      },
      survey: {
        saveSurvey: (gameId: string) =>
          HOST + SERVICE_URL.roleGame + `/game/${gameId}/survey`,
      },
      cases: {
        saveCases: (gameId: string) =>
          HOST + SERVICE_URL.roleGame + `/game/${gameId}/cases`,
      },
    },
    participant: {
      getGameInfo: (gameId: string) =>
        HOST + SERVICE_URL.roleGame + `/game/${gameId}/game_info`,
      getCase: (gameId: string, caseTypeIndex: number) =>
        HOST +
        SERVICE_URL.roleGame +
        `/game/${gameId}/case_type/${caseTypeIndex}`,
      setReady: (gameId: string) =>
        HOST + SERVICE_URL.roleGame + `/game/${gameId}/i_am_ready`,
      getRoundInfo: (gameId: string) =>
        HOST + SERVICE_URL.roleGame + `/game/${gameId}/round_info`,
      getMyAnswersForCurrentRound: (gameId: string, roundIndex: number) =>
        HOST +
        SERVICE_URL.roleGame +
        `/game/${gameId}/round/${roundIndex}/my_answers`,
      getMyResults: (gameId: string) =>
        HOST + SERVICE_URL.roleGame + `/game/${gameId}/my_results`,
      getReadyStatus: (gameId: string, roundIndex: number) =>
        HOST +
        SERVICE_URL.roleGame +
        `/game/${gameId}/round/${roundIndex}/ready_status`,
      addParticipantAssessment: (
        gameId: string,
        roundIndex: number,
        assessedParticipantId: string,
      ) =>
        HOST +
        SERVICE_URL.roleGame +
        `/game/${gameId}/round/${roundIndex}/participant/${assessedParticipantId}/assessment`,
      addExpertToMeeting: (meetingId: string) =>
        HOST +
        SERVICE_URL.roleGame +
        `/meeting/${meetingId}/add_expert_to_meeting`,
      removeExpert: (gameId: string, roundIndex: number) =>
        HOST +
        SERVICE_URL.roleGame +
        `/game/${gameId}/round/${roundIndex}/remove_expert_from_meeting`,
    },
  },
  voting: {
    getVoting: HOST + SERVICE_URL.voting + "/voting/{votingId}",
    getResultsReport:
      HOST + SERVICE_URL.voting + "/voting/{votingId}/result_report",
    updateVoting: HOST + SERVICE_URL.voting + "/voting/{votingId}",
    setShowComments:
      HOST + SERVICE_URL.voting + "/voting/{votingId}/show_comments",
    getVotes: HOST + SERVICE_URL.voting + "/votes",
    addVotes: HOST + SERVICE_URL.voting + "/votes",
    getAllVotes: HOST + SERVICE_URL.voting + "/votes/all",
  },
  moderator: {
    getAuthToken: HOST + SERVICE_URL.moderator + "/authenticate",
    isAuthenticated: HOST + SERVICE_URL.moderator + "/authenticated",
    getModerator: () => HOST + SERVICE_URL.moderator + `/moderator`,
    getModeratorById: (moderatorId) =>
      HOST + SERVICE_URL.moderator + `/moderator/${moderatorId}`,
    setCompanyName: () => HOST + SERVICE_URL.moderator + `/moderator/profile`,
    setCompanyNameById: (moderatorId) =>
      HOST + SERVICE_URL.moderator + `/moderator/${moderatorId}/profile`,
    setLogo: () => HOST + SERVICE_URL.moderator + `/moderator/logo`,
    deleteLogo: () => HOST + SERVICE_URL.moderator + `/moderator/logo`,
    findAll: () => HOST + SERVICE_URL.moderator + `/moderator/all`,
    setSubscriptionExpiration: (moderatorId) =>
      HOST +
      SERVICE_URL.moderator +
      `/moderator/${moderatorId}/subscription_expiration`,
    changeModeratorTariff: (moderatorId) =>
      HOST + SERVICE_URL.moderator + `/moderator/${moderatorId}/tariff`,
    getModeratorCustomTariff: (moderatorId, tariff) =>
      HOST + SERVICE_URL.moderator + `/moderator/${moderatorId}/custom_tariff/?tariffName=${tariff}`,
    updateModeratorCustomTariff: (moderatorId) =>
      HOST + SERVICE_URL.moderator + `/moderator/${moderatorId}/custom_tariff`,
    getAllTariffs: () =>
      HOST + SERVICE_URL.moderator + `/moderator/all_tariffs`,
    setModeratorSettings: (moderatorId) =>
      HOST + SERVICE_URL.moderator + `/moderator/${moderatorId}/settings`,
    loadModerators: HOST + SERVICE_URL.moderator + "/moderator/pageable",
    diagnostics: HOST + SERVICE_URL.moderator + "/diagnostics",
  },
  askQuestion: {
    getActivity: (activityId: string) =>
      HOST + SERVICE_URL.askQuestion + `/activity/${activityId}`,
    getActivityStatistics: (activityId: string) =>
      HOST + SERVICE_URL.askQuestion + `/activity/${activityId}/statistics`,
    getArguments: (questionId: string) =>
      HOST + SERVICE_URL.askQuestion + `/question/${questionId}/arguments`,
    getArgumentsCount: (activityId: string) =>
      HOST +
      SERVICE_URL.askQuestion +
      `/activity/${activityId}/arguments-count`,
    getArgumentsForProjector: (activityId: string) =>
      HOST + SERVICE_URL.askQuestion + `/activity/${activityId}/arguments`,
    settings: (activityId: string) =>
      HOST + SERVICE_URL.askQuestion + `/activity/${activityId}/settings`,
    getResultsReport: (activityId: string) =>
      HOST + SERVICE_URL.askQuestion + `/activity/${activityId}/result_report`,
    speakers: {
      addSpeaker: (activityId: string) =>
        HOST + SERVICE_URL.askQuestion + `/activity/${activityId}/speaker`,
      changeSpeakersOrder: (activityId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speakers_order`,
      changeSpeakerName: (activityId: string, speakerId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/name`,
      setActiveSpeaker: (activityId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/active_speaker`,
      changeSpeakerReadonly: (activityId: string, speakerId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/set_readonly`,
      setFocusedParticipantForSpeaker: (
        activityId: string,
        speakerId: string,
      ) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/set_focused_participant`,
      deleteSpeaker: (activityId: string, speakerId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}`,
    },
    questions: {
      addQuestion: (activityId: string, speakerId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/question`,
      deleteQuestion: (activityId: string, questionId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/question/${questionId}`,
      updateQuestion: (
        activityId: string,
        speakerId: string,
        questionId: string,
      ) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/question/${questionId}`,
      updateAi: (activityId: string, speakerId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/suggestions`,
      setQuestionApproved: (
        activityId: string,
        speakerId: string,
        questionId: string,
      ) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/question/${questionId}/approved`,
      setQuestionFocused: (
        activityId: string,
        speakerId: string,
        questionId: string,
      ) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/question/${questionId}/focused`,
      setQuestionAnswered: (
        activityId: string,
        speakerId: string,
        questionId: string,
      ) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/question/${questionId}/answered`,
      addComment: (activityId: string, speakerId: string, questionId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/question/${questionId}/comment`,
      updateComment: (
        activityId: string,
        speakerId: string,
        questionId: string,
        commentId: string,
      ) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/question/${questionId}/comment/${commentId}`,
      deleteComment: (activityId: string, commentId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/comments/${commentId}`,
      addArgument: (activityId: string, questionId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/question/${questionId}/argument`,
      updateArgument: (activityId: string, argumentId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/arguments/${argumentId}`,
      deleteArgument: (activityId: string, argumentId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/arguments/${argumentId}`,
      addVote: (activityId: string, speakerId: string, questionId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/question/${questionId}/vote`,
      updateVote: (activityId: string, speakerId: string, questionId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/question/${questionId}/vote`,
      deleteVote: (activityId: string, speakerId: string, questionId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/question/${questionId}/vote`,
      getHistory: (activityId: string, speakerId: string, questionId: string) =>
        HOST +
        SERVICE_URL.askQuestion +
        `/activity/${activityId}/speaker/${speakerId}/question/${questionId}/history`,
    },
  },
  tagCloud: {
    getTagCloud: (tagCloudId: string) =>
      HOST + SERVICE_URL.tagCloud + `/tag_cloud/${tagCloudId}`,
    addTag: (tagCloudId: string) =>
      HOST + SERVICE_URL.tagCloud + `/tag_cloud/${tagCloudId}/tag`,
    setInstruction: (tagCloudId: string) =>
      HOST + SERVICE_URL.tagCloud + `/tag_cloud/${tagCloudId}/instruction`,
    setSettings: (tagCloudId: string) =>
      HOST + SERVICE_URL.tagCloud + `/tag_cloud/${tagCloudId}/settings`,
    getResultsReport: (activityId: string) =>
      HOST + SERVICE_URL.tagCloud + `/tag_cloud/${activityId}/result_report`,
    deleteTag: (activityId: string) =>
      HOST + SERVICE_URL.tagCloud + `/tag_cloud/${activityId}/delete_tag`,
  },
  fillVacancy: {
    getActivity: HOST + SERVICE_URL.fillVacancy + "/activity/{activityId}",
    changeInstruction:
      HOST + SERVICE_URL.fillVacancy + "/activity/{activityId}/instruction",
    addGroup: HOST + SERVICE_URL.fillVacancy + "/activity/{activityId}/group",
    deleteGroup:
      HOST + SERVICE_URL.fillVacancy + "/activity/{activityId}/group/{groupId}",
    setVacanciesNumber:
      HOST +
      SERVICE_URL.fillVacancy +
      "/activity/{activityId}/vacancies_number",
    changeGroupName: HOST + SERVICE_URL.fillVacancy + "/group/{groupId}/name",
    applyForVacancy:
      HOST + SERVICE_URL.fillVacancy + "/vacancy/{vacancyId}/participantId",
    leaveVacancy:
      HOST + SERVICE_URL.fillVacancy + "/vacancy/{vacancyId}/participantId",
    changeParticipantsTeamNames:
      HOST +
      SERVICE_URL.fillVacancy +
      "/activity/{activityId}/change_participants_team_names",
  },
  miniActivities: {
    getStub: HOST + SERVICE_URL.miniActivities + "/stub/{stubId}",
    sendStub: HOST + SERVICE_URL.miniActivities + "/stub/update",
    getStubContent:
      HOST + SERVICE_URL.miniActivities + "/stub/{stubId}/stubContent",
    getDividingDataFieldIdForStub:
      HOST +
      SERVICE_URL.miniActivities +
      "/stub/{stubId}/dividing_data_field_id",
    setStubHtml: HOST + SERVICE_URL.miniActivities + "/stub/{stubId}/html",
    setParticipantReady: (stubId: string) =>
      HOST +
      SERVICE_URL.miniActivities +
      `/stub/${stubId}/set_participant_ready`,
    startRandomization: (eventId: string) =>
      build("miniActivities", `/event/${eventId}/random_choose`),
    closeRandomization: (eventId: string) =>
      build("miniActivities", `/event/${eventId}/random_choose_close`),
  },
  survey: {
    getSurvey: (surveyId: string) =>
      HOST + SERVICE_URL.survey + `/survey/${surveyId}`,
    updateSurvey: (surveyId: string) =>
      HOST + SERVICE_URL.survey + `/survey/${surveyId}`,
    addAnswers: (surveyId: string) =>
      HOST + SERVICE_URL.survey + `/survey/${surveyId}/answers_for_participant`,
    getSummary: (surveyId: string) =>
      HOST + SERVICE_URL.survey + `/survey/${surveyId}/summary`,
    getParticipantAnswers: (surveyId: string) =>
      HOST + SERVICE_URL.survey + `/survey/${surveyId}/answers_for_participant`,
    getResultsReport: (surveyId: string) =>
      HOST + SERVICE_URL.survey + `/survey/${surveyId}/result_report`,
    setShowSurveyResults: (surveyId: string) =>
      HOST + SERVICE_URL.survey + `/survey/${surveyId}/show_survey_results`,
    setSurveyClosedStatus: (surveyId: string) =>
      HOST + SERVICE_URL.survey + `/survey/${surveyId}/closed`,
    setAllowAnswersOnlyFromCaptain: (surveyId: string) =>
      HOST +
      SERVICE_URL.survey +
      `/survey/${surveyId}/allow_answers_only_from_captains`,
    setHideNamesInOpenQuestion: (surveyId: string) =>
      HOST +
      SERVICE_URL.survey +
      `/survey/${surveyId}/hide_names_in_open_question`,
    setShowCorrectAnswers: (surveyId: string) =>
      HOST + SERVICE_URL.survey + `/survey/${surveyId}/show_correct_answers`,
    setShowParticipantsResults: (surveyId: string) =>
      HOST +
      SERVICE_URL.survey +
      `/survey/${surveyId}/show_participants_results`,
    setQuestionsDisplayMode: (surveyId: string) =>
      HOST +
      SERVICE_URL.survey +
      `/survey/${surveyId}/set_display_questions_mode`,
    setCorrectAnswerPerQuestionMode: (surveyId: string) =>
      build("survey", `/survey/${surveyId}/set_correct_answer_per_question`),
    setCurrentDisplayedQuestionId: (surveyId: string) =>
      HOST +
      SERVICE_URL.survey +
      `/survey/${surveyId}/set_current_displayed_question_id`,
  },
  auth: {
    requestAuthToken: HOST + SERVICE_URL.auth + "/oauth/token",
    getUser: HOST + SERVICE_URL.auth + "/user",
    loadEventSecurityConfig: () => build("auth", "/event_security_config"),
    setAuthenticateOnLoginAttempt: (eventId) =>
      build(
        "auth",
        `/event_security_config/event/${eventId}/authenticate_on_login_attempt`,
      ),
    setAllowOnlyOneAuthAtATime: (eventId) =>
      build(
        "auth",
        `/event_security_config/event/${eventId}/allow_only_one_auth_at_a_time`,
      ),
    logout: HOST + SERVICE_URL.auth + "/oauth/logout",
    revokeToken: HOST + SERVICE_URL.auth + "/oauth/revoke_token",
    logoutAllParticipants: (eventId: string) =>
      build("auth", `/event/${eventId}/participants/logout`),
    logoutParticipant: (participantId: string) =>
      build("auth", `/participant/${participantId}/logout`),
    deleteModerator: () => HOST + SERVICE_URL.auth + `/account`,
    changePassword: () =>
      HOST + SERVICE_URL.auth + `/moderator/change_password`,
    changeUsername: () =>
      HOST + SERVICE_URL.auth + `/moderator/change_username`,
    changeModeratorUser: (moderatorId) =>
      HOST + SERVICE_URL.auth + `/moderator/${moderatorId}/change`,
    createModerator: HOST + SERVICE_URL.auth + "/moderator",
    deleteModeratorById: (moderatorId) =>
      HOST + SERVICE_URL.auth + `/moderator/${moderatorId}`,
    setPasswordAfterRegistration: () =>
      HOST + SERVICE_URL.auth + `/moderator/external-set-password`,
    getRegisterInfo: (securityToken) =>
      HOST + SERVICE_URL.auth + `/moderator/register-info/${securityToken}`,
    resetFailuresCount: (moderatorId: string) =>
      HOST + SERVICE_URL.auth + `/moderator/${moderatorId}/reset_failure_count`,
  },
  captain: {
    getCaptain: (activityId: string) =>
      HOST + SERVICE_URL.miniActivities + `/activity/${activityId}`,
    sendCaptain: (activityId: string) =>
      HOST + SERVICE_URL.miniActivities + `/activity/${activityId}`,
    chooseCaptain: (teamId: string) =>
      HOST + SERVICE_URL.miniActivities + `/captain/${teamId}`,
  },
  brainstorm: {
    moderator: {
      getActivity: (activityId: string) =>
        HOST + SERVICE_URL.brainstorm + `/activity/${activityId}`,
      setSettings: (activityId: string) =>
        HOST + SERVICE_URL.brainstorm + `/activity/${activityId}`,
      setIsModerationEnabled: (activityId: string) =>
        HOST +
        SERVICE_URL.brainstorm +
        `/activity/${activityId}/is_moderation_enabled`,
      setIsWorkStopped: (activityId: string) =>
        HOST +
        SERVICE_URL.brainstorm +
        `/activity/${activityId}/is_work_stopped`,
      setIsAllStatementsToParticipantsShown: (activityId: string) =>
        HOST +
        SERVICE_URL.brainstorm +
        `/activity/${activityId}/is_all_statements_to_participants_shown`,
      setIsResultsShown: (activityId: string) =>
        HOST +
        SERVICE_URL.brainstorm +
        `/activity/${activityId}/is_results_shown`,
      setIsAllowedOnlyCaptainAssessment: (activityId: string) =>
        HOST +
        SERVICE_URL.brainstorm +
        `/activity/${activityId}/is_allowed_only_captain_assessment`,
      setIsTopShownToParticipants: (activityId: string) =>
        HOST +
        SERVICE_URL.brainstorm +
        `/activity/${activityId}/is_top_shown_to_participants`,
      setQuadrantsView: (activityId: string) =>
        HOST + SERVICE_URL.brainstorm + `/activity/${activityId}/quadrants`,
      setCurrentStageIndex: (activityId: string, currentStageIndex: number) =>
        HOST +
        SERVICE_URL.brainstorm +
        `/activity/${activityId}/stage/${currentStageIndex}`,
      setStatementApproved: (activityId: string, statementId: string) =>
        HOST +
        SERVICE_URL.brainstorm +
        `/activity/${activityId}/statement/${statementId}/approved`,
      updateStatement: (activityId: string, statementId: string) =>
        HOST +
        SERVICE_URL.brainstorm +
        `/activity/${activityId}/statement/${statementId}`,
      downloadReport: (activityId: string) =>
        HOST + SERVICE_URL.brainstorm + `/activity/${activityId}/result_report`,
    },
    participant: {
      getParticipantBrainstormActivity: (activityId: string) =>
        HOST + SERVICE_URL.brainstorm + `/participant/activity/${activityId}`,
      createOrUpdateStatement: (activityId: string) =>
        HOST + SERVICE_URL.brainstorm + `/activity/${activityId}/statement`,
      createOrUpdateAssessment: (activityId: string) =>
        HOST + SERVICE_URL.brainstorm + `/activity/${activityId}/assessment`,
      setStatementsPriority: (activityId: string) =>
        HOST +
        SERVICE_URL.brainstorm +
        `/activity/${activityId}/statements/priority`,
    },
  },
  classification: {
    moderator: {
      getActivity: (activityId: string) =>
        HOST + SERVICE_URL.classification + `/${activityId}`,
      changeSettings: (activityId: string) =>
        HOST + SERVICE_URL.classification + `/${activityId}/settings`,
      saveTasks: (activityId: string) =>
        HOST + SERVICE_URL.classification + `/${activityId}/tasks`,
      downloadReport: (activityId: string) =>
        HOST + SERVICE_URL.classification + `/${activityId}/result_report`,
    },
    participant: {
      getActivity: (activityId: string) =>
        HOST + SERVICE_URL.classification + `/participant/${activityId}`,
      saveTaskAnswers: (activityId: string) =>
        HOST +
        SERVICE_URL.classification +
        `/participant/${activityId}/answers`,
    },
  },
};
