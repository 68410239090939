<span class="middle-title">{{ data.title }}</span>

<div class="select-wrapper">
  <tellsy-select
    [options]="data.options"
    [placeholder]="data.placeholder"
    [(ngModel)]="data.selectedValue"
  ></tellsy-select>
</div>
<tellsy-button theme="light" (clicked)="close()">
  {{ data.btnText }}
</tellsy-button>
