import { Injectable } from "@angular/core";
import { UIRole } from "@app/store/auth/model";
import {
  ActivityWebsocketActions,
  EventWebsocketActions,
  ProjectorWebsocketActions,
  TimerWebsocketActions,
  WorkspaceWebsocketActions,
} from "@app/store/event/actions";
import { EventFacade } from "@app/store/event/facades/event.facade";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EventStompService } from "../services/event-stomp.service";

@Injectable({ providedIn: "root" })
export class EventStompFacade {
  constructor(
    private stomp: EventStompService,
    private store: Store,
    private eventFacade: EventFacade,
  ) {}

  dispatchOnTeamActivityChanged({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getTeamActiveActivityChanged$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ activity }) =>
        this.store.dispatch(
          ActivityWebsocketActions.changeForTeam({ activity }),
        ),
      );
  }

  dispatchOnEventRename({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getEventRenameMessages$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ name }) =>
        this.store.dispatch(EventWebsocketActions.setName({ name })),
      );
  }

  dispatchOnActivityRename({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getActivityNameChangedMessages$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ activityId, newName: name }) =>
        this.store.dispatch(
          ActivityWebsocketActions.setName({ activityId, name }),
        ),
      );
  }

  dispatchOnSubEventLinksChanged({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getSubEventLinksChanged$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ parentEventName: name, eventLinks: subEventsLinks }) =>
        this.store.dispatch(
          EventWebsocketActions.setSubEventsLinks({ name, subEventsLinks }),
        ),
      );
  }

  dispatchOnEventInformationBlocksDataChanges({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getWebinarChangedMessages$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ webinar }) =>
        this.store.dispatch(
          EventWebsocketActions.setWebinar({ eventId, webinar }),
        ),
      );
    this.stomp
      .getSharedIframeUrlMessages$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ url }) =>
        this.store.dispatch(
          EventWebsocketActions.setSharedIframeUrl({ eventId, url }),
        ),
      );
    this.stomp
      .getInformationForParticipantMessages$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ information }) =>
        this.store.dispatch(
          EventWebsocketActions.setInfoForParticipant({
            infoForParticipantHtml: information,
          }),
        ),
      );
  }

  dispatchOnParticipantsActivityTrackingChanges({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getParticipantsActivityTrackingChangedMessage$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ trackParticipantTab }) =>
        this.store.dispatch(
          EventWebsocketActions.setParticipantsActivityTracking({
            trackParticipantTab,
          }),
        ),
      );
  }

  dispatchOnThemeChanges({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getThemeChangeMessage$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ theme }) =>
        this.store.dispatch(EventWebsocketActions.setTheme({ eventId, theme })),
      );
    this.stomp
      .getThemeLogoChanged$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ showEventLogo: show, newEventLogoFileName: fileName }) =>
        this.store.dispatch(
          EventWebsocketActions.setLogo({
            eventId,
            show,
            fileName,
          }),
        ),
      );
  }

  dispatchOnActivitiesChanges({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getCommonActiveActivityChangedMessages$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ activity }) => {
        this.store.dispatch(ActivityWebsocketActions.setActive({ activity }));
      });

    this.stomp
      .getEventActivitiesChangedMessages$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ activities }) => {
        this.store.dispatch(
          ActivityWebsocketActions.setActivities({ activities }),
        );
      });
  }

  dispatchOnActivityCreated({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getActivityCreatedMessages$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ activity }) =>
        this.store.dispatch(ActivityWebsocketActions.add({ activity })),
      );
  }

  dispatchOnFeedbackChanged({
                              eventId,
                              role,
                              destroy$,
                            }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getFeedBackSubEventIdChangedMessages$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe();
  }

  dispatchOnTimerChanges({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getTimerStartedMessage$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        if (role !== "moderator") {
          this.store.dispatch(TimerWebsocketActions.start({ eventId }));
        }

        this.eventFacade.loadTimer(eventId);
      });
    this.stomp
      .getTimerPausedMessage$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        if (role === "moderator") {
          this.eventFacade.loadTimer(eventId);
        } else {
          this.store.dispatch(TimerWebsocketActions.stop({ eventId }));
        }
      });
    this.stomp
      .getTimerCanBeNegativeChangedMessage$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ canBeNegative }) => {
        if (role === "moderator") {
          this.eventFacade.loadTimer(eventId);
        } else {
          this.store.dispatch(
            TimerWebsocketActions.setCanBeNegative({ eventId, canBeNegative }),
          );
        }
      });
  }

  dispatchOnProjectorActivityChanges({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getProjectorActivityChanged$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ activityType, queryParams, activityId, teamIds }) => {
        this.store.dispatch(
          ProjectorWebsocketActions.setActivity({
            eventId,
            activityId,
            activityType,
            queryParams,
            teamIds,
          }),
        );
      });
  }

  dispatchOnProjectorFullscreenTimerChanges({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getFullscreenTimerChangeMessage$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ fullscreen: showFullscreenTimer }) =>
        this.store.dispatch(
          ProjectorWebsocketActions.setTimerFullscreen({ showFullscreenTimer }),
        ),
      );
  }

  dispatchOnWorkspaceChanges({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getWorkspaceDocumentUploaded$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ document }) =>
        this.store.dispatch(
          WorkspaceWebsocketActions.addDocument({ document }),
        ),
      );
    this.stomp
      .getWorkspaceDocumentDeleted$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ documentId }) =>
        this.store.dispatch(
          WorkspaceWebsocketActions.deleteDocument({ eventId, documentId }),
        ),
      );
    this.stomp
      .getWorkspaceDocumentRenamed$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ documentId, fileName }) =>
        this.store.dispatch(
          WorkspaceWebsocketActions.renameDocument({
            eventId,
            documentId,
            fileName,
          }),
        ),
      );
    this.stomp
      .getWorkspaceDocumentHiddenChanged$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ documentId, isHidden }) =>
        this.store.dispatch(
          WorkspaceWebsocketActions.setDocumentHidden({
            eventId,
            documentId,
            isHidden,
          }),
        ),
      );
    this.stomp
      .getWorkspaceAllDocumentsProtectedChanged$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ documentsProtected }) =>
        this.store.dispatch(
          WorkspaceWebsocketActions.setAllDocumentsProtected({
            eventId,
            documentsProtected,
          }),
        ),
      );
    this.stomp
      .getWorkspaceAllDocumentsHiddenChanged$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ documentsHidden }) =>
        this.store.dispatch(
          WorkspaceWebsocketActions.setAllDocumentsHidden({
            eventId,
            documentsHidden,
          }),
        ),
      );
    this.stomp
      .getWorkspaceDocumentsOrderChanged$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ documentsIds, documentsOrders }) =>
        this.store.dispatch(
          WorkspaceWebsocketActions.setDocumentsOrders({
            eventId,
            documentsIds,
            documentsOrders,
          }),
        ),
      );

    this.stomp
      .getWorkspaceScheduleChanged$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(({ schedule }) =>
        this.store.dispatch(
          WorkspaceWebsocketActions.setSchedule({ schedule }),
        ),
      );
  }

  dispatchOnRandomizationChanges({
    eventId,
    role,
    destroy$,
  }: {
    eventId: string;
    role: UIRole;
    destroy$: Observable<void>;
  }) {
    this.stomp
      .getRandomizationChanged$({
        eventId,
        role,
      })
      .pipe(takeUntil(destroy$))
      .subscribe(
        ({ running: isRunning, closed: isClosed, variants, chosenVariant }) =>
          this.store.dispatch(
            EventWebsocketActions.setRandomization({
              randomization: {
                isRunning,
                isClosed,
                variants,
                chosenVariant,
              },
            }),
          ),
      );
  }
}
